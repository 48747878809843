<template>
  <div>
    <h1>实名认证 - 实时录像</h1>
    <p>请正视摄像头，录制视频进行实名认证。</p>
    <!-- 视频容器 -->
    <div class="video-container">
      <video class="video-container-video" ref="video" muted autoplay playsinline webkit-playsinline></video>
    </div>
    <!-- 停止录像后显示的图片 -->
<!--    <img id="snapshot" :src="snapshotSrc" alt="录像最后画面" v-if="snapshotSrc">-->
    <button @click="stopRecording" :disabled="!isRecording" class="auth-button">认证</button>
  </div>
</template>

<script>
import request from "@/api";
export default {
  data() {
    return {
      mediaStream: null,
      mediaRecorder: null,
      recordedChunks: [],
      isRecording: false,
      downloadUrl: '',
      downloadName: 'verification_video.webm',
      snapshotSrc: '',
      form:{
        nameText:'',
        numberText:''
      }
    };
  },
  created() {
    this.form.nameText=this.$route.query.nameText
    this.form.numberText=this.$route.query.numberText
  },
  mounted() {
    this.openCamera();
  },
  methods: {
    openCamera() {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then((stream) => {
          this.mediaStream = stream;
          this.$refs.video.srcObject = stream;
          this.startRecording(); // 初始化时自动开始录制
        })
        .catch((error) => {
          console.error('无法访问摄像头:', error);
        });
    },
    //请求接口
    submitForm() {
      request.post(process.env.VUE_APP_PX_URL+'/uua/verifyFace',{
        nameText:this.form.nameText,
        numberText:this.form.numberText,
        idPhoto:this.snapshotSrc
      }).then(res=>{
        if (res.data=='success'){
          this.$router.push({path: '/authSuccess', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
        }else {
          this.$router.push({path: '/authErr', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
        }
      }).catch(err=>{
        this.$toast.fail(err.message)
      })
    },
    startRecording() {
      this.mediaRecorder = new MediaRecorder(this.mediaStream);

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };

      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
        this.recordedChunks = [];
        this.downloadUrl = URL.createObjectURL(blob);
        this.downloadName = 'verification_video.webm';

        // 使用 canvas 绘制视频的最后一帧
        this.$refs.video.pause();
        const canvas = document.createElement('canvas');
        canvas.width = this.$refs.video.videoWidth;
        canvas.height = this.$refs.video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
        this.snapshotSrc = canvas.toDataURL('image/png');
        this.submitForm()
        console.log(this.snapshotSrc)
      };
      if (this.mediaRecorder) {
        this.mediaRecorder.start();
      }
      this.isRecording = true;
    },

    stopRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive' && this.mediaStream) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    }
  }
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

/* 视频容器样式，将视频变为圆形并水平居中 */
.video-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 60px auto; /* 确保水平居中 */
  position: relative;
  border: 4px solid #007bff; /* 更改为蓝色边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

/* 视频样式 */
.video-container-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保视频内容覆盖整个容器 */
  position: absolute;
  top: 0;
  left: 0;
}

/* 停止录像后显示的图片样式 */
#snapshot {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
}

/* 美化按钮样式 */
.auth-button {
  padding: 12px 48px;
  margin: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

a {
  display: block;
  margin-top: 20px;
  font-size: 16px;
}
</style>
